import React from 'react';
import './App.css';
import logo from './png-01.png';
import ServiceDetails from './ServiceDetails';

function App() {
  return (
    <div className="App">
      <div className="sticky-promo">
        <p>
          <span className="emoji">🎉</span>
          <strong>EXKLUSIVES ANGEBOT:</strong> Kostenlose Schutzfolie bei Ihrem Besuch im Laden!
          <span className="emoji">🎉</span>
        </p>
        <a href="https://api.whatsapp.com/send?phone=496115804881&text=Hallo%2C%0AWie%20kann%20das%20Angebot%20%22Kostenlose%20Schutzfolie%22%20einl%C3%B6sen%3F" className="promo-button" target="_blank" rel="noopener noreferrer">Jetzt einlösen</a>
      </div>
      <header className="App-header">
        <div className="header-content">
          <div className="App-logo-container">
            <img src={logo} className="App-logo" alt="Handyshop Wiesbaden Logo" />
          </div>
          <h1>Handy Shop 24</h1>
        </div>
        <nav>
          <ul>
            <li><a href="#ueber-uns">Über uns</a></li>
            <li><a href="#dienstleistungen">Dienstleistungen</a></li>
            <li><a href="#produkte">Produkte</a></li>
            <li><a href="#kontakt">Kontakt</a></li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="ueber-uns" className="hero">
          <h2>Ihr Experte für Mobiltelefone und Zubehör</h2>
          <p>Willkommen im Handyshop Wiesbaden - Seit 2005 bieten wir erstklassigen Service und hochwertige Produkte zu fairen Preisen.</p>
          <a href="#kontakt" className="cta-button">Jetzt kontaktieren</a>
        </section>
        <section id="dienstleistungen">
          <h2>Unsere Dienstleistungen</h2>
          <div className="service-grid">
            <ServiceDetails
              title="Reparaturen"
              description="Professionelle Reparaturen für alle gängigen Marken und Modelle."
              details={{
                icon: "tools",
                items: [
                  "Display-Reparaturen",
                  "Akku-Austausch",
                  "Wasserschaden-Behandlung",
                  "Software-Probleme",
                  "Kamera-Reparaturen"
                ],
                priceRange: "30€ - 250€ je nach Reparatur"
              }}
            />
            <ServiceDetails
              title="An- und Verkauf"
              description="Große Auswahl an neuen und gebrauchten Telefonen zu attraktiven Preisen."
              details={{
                icon: "exchange-alt",
                items: [
                  "Ankauf gebrauchter Smartphones",
                  "Verkauf von Neugerten",
                  "Verkauf von geprüften Gebrauchtgeräten",
                  "Faire Preise beim An- und Verkauf",
                  "Große Auswahl an Marken und Modellen"
                ],
                priceRange: "Ankauf: 50€ - 500€, Verkauf: 100€ - 1000€"
              }}
            />
            <ServiceDetails
              title="Zubehör"
              description="Umfangreiches Sortiment an Handyzubehör für jeden Bedarf."
              details={{
                icon: "mobile-alt",
                items: [
                  "Schutzhüllen und Cases",
                  "Displayschutzfolien",
                  "Ladekabel und Netzteile",
                  "Kopfhörer und Headsets",
                  "Powerbanks und externe Akkus"
                ],
                priceRange: "5€ - 100€"
              }}
            />
          </div>
        </section>
        <section id="produkte">
          <h2>Unsere Top-Produkte</h2>
          <div className="product-slider">
            {/* Hier können Sie einen Slider mit Produktbildern einfügen */}
          </div>
        </section>
        <section id="kontakt">
          <h2>Kontakt</h2>
          <div className="contact-grid">
            <div className="contact-info">
              <p><i className="fas fa-map-marker-alt"></i> Wellritzstraße 17, 65183 Wiesbaden</p>
              <div className="contact-options">
                <a href="tel:06115804881" className="contact-button">
                  <i className="fas fa-phone"></i> Anrufen
                </a>
                <a href="mailto:info@hsw24.net" className="contact-button">
                  <i className="fas fa-envelope"></i> E-Mail
                </a>
                <a href="https://wa.me/496115804881" className="contact-button">
                  <i className="fab fa-whatsapp"></i> WhatsApp
                </a>
              </div>
            </div>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81819.40475721691!2d8.23305379461493!3d50.14511819248096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bdbe76625e5739%3A0x2902b7ad33135435!2sHandy%20Shop%2024!5e0!3m2!1sde!2sde!4v1728069600064!5m2!1sde!2sde"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2023 Handyshop Wiesbaden. Alle Rechte vorbehalten.</p>
        <div className="social-media">
          <a href="#" aria-label="Facebook"><i className="fab fa-facebook"></i></a>
          <a href="#" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
          <a href="#" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
        </div>
      </footer>
    </div>
  );
}

export default App;