import React, { useState } from 'react';

const ServiceDetails = ({ title, description, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="service-item">
      <i className={`fas fa-${details.icon}`}></i>
      <h3>{title}</h3>
      <p>{description}</p>
      <button onClick={() => setIsOpen(!isOpen)} className="details-button">
        {isOpen ? 'Weniger anzeigen' : 'Mehr erfahren'}
      </button>
      {isOpen && (
        <div className="service-details">
          <h4>Details zu {title}</h4>
          <ul>
            {details.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {details.priceRange && (
            <p>Preisbereich: {details.priceRange}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceDetails;
